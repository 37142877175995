.logo:hover {
    transform: scale(1.05);
  }
  
  .nav-link {
    position: relative;
  }
  
  .nav-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: white;
    transition: all 0.3s ease-in-out;
  }
  
  .nav-link:hover::after {
    width: 100%;
    left: 0;
  }