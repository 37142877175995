body, html {
  height: 100%;
  margin: 0;
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

.home-page {
  height: 100vh;
  position: relative;
}

.home-page::before {
  content: "";
  background-image: url('/src/Assests/background.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.text-container {
  margin-top: -150px;
}

.button-container {
  position: relative;
  z-index: 1;
}